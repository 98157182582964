<!-- eslint-disable max-lines -->
<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="application-detail"
            v-loading="detailLoading">
            <!-- 标题区域 -->
            <div class="header-box">
                <h4>准入申请</h4>
                <div class="header-info">
                    <div class="tips">准入提示：{{ detailDataLocal.accessWarn }}</div>
                    <div class="apply-info">
                        <div>申请人：{{ detailDataLocal.applicantName }}</div>
                        <div>部门：{{ detailDataLocal.applicantDepartName }}</div>
                    </div>
                </div>
            </div>
            <!-- 按钮区域 -->
            <div class="detail-toolBar">
                <lots-button type="primary" v-if="!(readonly || [2, 3, 4, 7].includes(+detailDataLocal.mipStatus)
                    || ([1, 5, 6].includes(+detailDataLocal.mipStatus) && +detailDataLocal.isPass === 1))"
                    :loading="saveLoading"
                    @click="handleSave">暂存
                </lots-button>
                <lots-button type="primary"
                    v-if="!(!readonly || [2, 3, 4, 7].includes(+detailDataLocal.mipStatus)
                    || ([1, 5, 6].includes(+detailDataLocal.mipStatus) && +detailDataLocal.isPass === 1))"
                    @click="handleCheck" :loading="checkLoading">提交校验
                </lots-button>
                <lots-button type="primary" v-if="!(!readonly || [2, 3, 4, 7].includes(+detailDataLocal.mipStatus))"
                    @click="handleEdit">编辑
                </lots-button>
                <el-tooltip class="item-flow-right" effect="dark" content="刷新" placement="bottom">
                    <lots-button
                        size="mini"
                        icon="el-icon-refresh"
                        v-show="code !== '0000'"
                        class="refresh"
                        @click="refleshPage()"
                        :loading="detailLoading">
                    </lots-button>
                </el-tooltip>
            </div>
            <!-- 表单区域 -->
            <collapse-infos
                ref="upholdHealder"
                :detailData="detailDataLocal"
                :collapse="COLLAPSE"
                :activeCollapses="activeCollapses"
                @change="handleInfosChange">
                <template v-slot:collapse-append="{form, itemSetting, rIndex, collapseItem, dictData}">
                    <span v-if="itemSetting.prop === 'propertyRightFile'">
                        <lots-upload-button :text="'上传附件'" :bucket="'annto-lcrm'" :disabled="readonly"
                            @callback="(data) => { upLoadData(form, itemSetting, data); }">
                        </lots-upload-button>
                        <div class="file-infos">
                            <template v-if="form[itemSetting.prop]
                                && form[itemSetting.prop].length">
                                <div
                                    class="file-list"
                                    v-for="(item, index) in form[itemSetting.prop]"
                                    :key="index + item.fileName">
                                    <div
                                        class="file-name"
                                        :title="item.fileName">
                                        {{`${index+1}. `}}
                                        <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
                                    </div>
                                    <div class="delete-btn">
                                        <el-button
                                            type="text"
                                            plain>
                                            <a :href="item.fileUrl" target="_blank">下载</a>
                                        </el-button>
                                        <el-button
                                            type="text"
                                            :disabled="readonly"
                                            plain
                                            @click="handleDeleteFile(form, itemSetting, index)">删除</el-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </span>
                    <span v-else-if="itemSetting.prop === 'cdcFile'">
                        <lots-upload-button :text="'上传附件'" :bucket="'annto-lcrm'" :disabled="Boolean(form[itemSetting.prop].length) || readonly"
                            :accept="'xlsx'"
                            @callback="(data) => { upLoadData(form, itemSetting, data); }">
                        </lots-upload-button>
                        <lots-button type="text" class="down-mould-btn" @click="handleDownloadMould">下载模板</lots-button>
                        <span class="upload-tips">( 提示：请下载模板，编辑完成后上传 )</span>
                        <div class="file-infos">
                            <template v-if="form[itemSetting.prop] && form[itemSetting.prop].length">
                                <div
                                    class="file-list"
                                    v-for="(item, index) in form[itemSetting.prop]"
                                    :key="index + item.fileName">
                                    <div
                                        class="file-name"
                                        :title="item.fileName">
                                        {{`${index+1}. `}}
                                        <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
                                    </div>
                                    <div class="delete-btn">
                                        <el-button
                                            type="text"
                                            plain>
                                            <a :href="item.fileUrl" target="_blank">下载</a>
                                        </el-button>
                                        <el-button
                                            type="text"
                                            :disabled="readonly"
                                            plain
                                            @click="handleDeleteFile(form, itemSetting, index)">删除
                                        </el-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </span>
                    <!-- <table-box
                        v-else-if="itemSetting.prop === 'sjmx'"
                        ref="opportunityTableRef"
                        :key="tableKey"
                        :height="tableHeight"
                        :columns="itemSetting.columns"
                        :rows="opportunityTableData">
                    </table-box> -->
                    <el-table
                        v-else-if="itemSetting.prop === 'sjmx'"
                        :data="opportunityTableData"
                        style="width: 100%"
                        :height="tableHeight">
                        <el-table-column
                            v-for="(col, cIndex) in itemSetting.columns"
                            :key="col.prop + cIndex"
                            :prop="col.prop"
                            :label="col.label"
                            :show-overflow-tooltip="lodash.get(col, 'showTooltip', true)"
                            v-bind="{ ...col, type: lodash.get(col, 'columnType', '') }">
                            <template v-slot="{ row, $index: rIndex }">
                                <div v-if="col.type === 'select'">
                                    <span v-if="dictData[col.optionsKey] && row[col.prop]">{{ dictData[col.optionsKey][row[col.prop]] || row[col.prop] ||'' }}</span>
                                </div>
                                <div v-else>{{ row[col.prop] || '' }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <span v-else-if="!itemSetting.optionsKey" class="custom-span" :title="form[itemSetting.prop]">
                        {{(form[itemSetting.prop] || form[itemSetting.prop] === 0) ? form[itemSetting.prop] : '--'}}
                    </span>
                </template>
            </collapse-infos>
            <!-- iflow区域 -->
            <el-collapse
                v-if="detailDataLocal.mipFlowId"
                class="contract-collapse detail-more-collapse"
                value="mipInfo">
                <el-collapse-item title="准入审批" name="mipInfo">
                    <div class="header-operation">
                        <lots-button
                            size="mini"
                            type="primary"
                            @click="submitMip"
                            v-if="!(!readonly || (+detailDataLocal.isPass !== 1 && [1, 5, 6].includes(+detailDataLocal.mipStatus)))"
                            :loading="btnloading">
                            mip提交
                        </lots-button>
                    </div>
                    <iframe
                        v-if="!btnloading"
                        class="ifr"
                        id="myIframe"
                        ref="iframeRef"
                        :src="mipUrl" />
                </el-collapse-item>
            </el-collapse>
        </div>
    </paper>
</template>

<script>
import { ref, watch, computed, getCurrentInstance, onActivated } from '@vue/composition-api';
import paper from '@/components/core/Paper.vue';
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import lotsUploadButton from '@/components/lots/oss/UploadBtn';
import {
    opportunityApplicationApi, neoCrmProjectQueryApi, cdcFileAnalysisApi, cdcFileTemplateDownloadApi,
    neoStagingProjectApi, neoSyncProjectApi } from '@mdm/api/opportunityModules/opportunityApplication.js';
import { approveProcess } from '@/modules/mdm/api/contractManage/detailMainApi.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import { Message, MessageBox } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
import { collapse } from './collapseInfosConfig.js';
import _ from 'lodash';
import configurl from '@/config/user.env';
import { getProcessInfoApi } from '@mdm/api/contractManage/mipIframeApi.js';
const { MAGIC_NUMBER } = utils;
export default {
    name: 'opportunityApplication',
    components: { paper, lotsButton, CollapseInfos, lotsUploadButton },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(collapse);
        const detailLoading = ref(false);
        const btnloading = ref(false);
        const disabledFlag = ref(false);
        const code = ref('');
        const readonly = ref(false);
        const detailDataLocal = ref({
            projectName: '',
            overallRiskLevel: '',
            propertyRightFile: [],
            cdcFile: [],
            riskItemsNum: ''
        });
        const activeCollapses = ref(['focusInfo', 'capabilityInfo', 'financeInfo', 'cooperationInfo', 'riskInfo', 'promiseInfo', 'CDCInfo', 'opportunityInfo']);
        const mipUrl = ref('');
        const opportunityTableData = ref([]);
        const upstreamPaymentCycleNum = 60; // 账期>60天必填
        const lodash = computed(() => { return _; });
        const COLLAPSE = computed(() => { // 配置项动态计算
            const {
                settlementMode, propertyRight, businessType, overallRiskLevel, riskItemsNum, upstreamPaymentCycle
            } = detailDataLocal.value;
            config.forEach((v, i) => {
                if (v.formName === 'promiseInfo') { // 【风险等级（综合）】不为中风险，中风险引入承诺标签组隐藏；
                    v.visible = overallRiskLevel === '中风险';
                }
                if (v.formName === 'CDCInfo') { // 【是否包含CDC】不为是时，CDC准入标签组隐藏；
                    v.visible = isCDC.value;
                }
                // eslint-disable-next-line complexity
                v.formList.forEach((v2, i2) => {
                    if (readonly.value) {
                        if (v2.inputType !== 'textarea') {
                            v2.type = 'custom';
                        } else {
                            v2.disabled = true;
                        }
                    } else {
                        v2.type = (v2.sourceType || v2.type);
                        const readonlyKeys = ['specialReason', 'overallRiskInfo', 'overallTaxContravention', 'badCreditRecord', 'defaultLiabilityAndLoss'];
                        !readonlyKeys.includes(v2.prop) && (v2.disabled = false);
                    }
                    if (v2.prop === 'propertyRightFile') { // 客户与货物的货权关系为双非业务时，双非特批附件必填；
                        v2.rule[0].required = propertyRight === '3';
                    }
                    if (v2.prop === 'acceptanceRate') { // 结算方式为承兑时，承兑比例必填
                        v2.rule[0].required = settlementMode === '2';
                    }
                    if (v2.prop === 'focusBusinessFormats') { // 聚焦业态：业务类别包含快递时必填，不包含时清空
                        // eslint-disable-next-line max-nested-callbacks
                        v2.rule[0].required = businessType && businessType.includes('快递');
                    }
                    if (v2.prop === 'isWarehouse') { // 是否控仓：业务类别包含快递时必填，不包含时清空
                        // eslint-disable-next-line max-nested-callbacks
                        v2.rule[0].required = businessType && businessType.includes('快递');
                    }
                    if (['collectionControl', 'badDebtAccountability', 'cooperationQuota', 'promisee'].includes(v2.prop)) {
                        // 回款管控措施承诺、坏账追责承诺、合作额度控制承诺、承诺人：风险等级为中风险时必填
                        v2.rule[0].required = overallRiskLevel === '中风险';
                    }
                    if (v2.prop === 'cdcFile') { // 包含CDC,cdc附件必填
                        v2.rule[0].required = isCDC.value;
                    }
                    if (['cdcScore1', 'cdcScore2', 'cdcScore', 'riskItemsNum'].includes(v2.prop)) {
                        // 包含CDC时，项目基本信息调研得分、项目复杂度评估得分、综合得分、重大风险项 必填
                        v2.rule[0].required = isCDC.value;
                    }
                    if (v2.prop === 'riskItemsDescribe') { // CDC重大风险项为高风险(>=3)时，重大风险项描述必填，不为高风险时清空重大风险项描述；
                        v2.rule[0].required = +riskItemsNum >= 3;
                    }
                    if (v2.prop === 'profitMargin') { // 账期>60天必填
                        v2.rule[0].required = +upstreamPaymentCycle > upstreamPaymentCycleNum;
                    }
                });
            });
            return config;
        });
        // 从商机明细中的业务类别判断，只要业务类别包含仓储或者装卸即可显示
        const containsChar = () => {
            const businessTypeList = ['仓储', '装卸'];
            return opportunityTableData.value.some(item => {
                return businessTypeList.some(it => item.businessType?.includes(it)); // 业务类别包含仓储或者装卸
            });
        };
        const isCDC = computed(() => { // 当商机明细存在仓库类型为CDC，业务类别为仓储或装卸，且签单计划≥300万的商机时，视为包含CDC
            return opportunityTableData.value.some(item => item.whType === '1'
                && containsChar()
                && +item.signingPlan >= MAGIC_NUMBER.THREE_HUNDRED);
        });
        const tableHeight = computed(() => {
            return opportunityTableData.value.length * MAGIC_NUMBER.FOURTY + MAGIC_NUMBER.FIFTY_ONE + 7 + 'px';
        });
        const refleshPage = (applicationId = ctx.$route.query.applicationId) => {
            if (isHandleEdit.value) {
                MessageBox.confirm(`你当可能存在编辑未保存的数据，刷新页面将覆盖编辑的数据，确认继续刷新？`, `注意：`, {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _initData(applicationId);
                });
            } else {
                _initData(applicationId);
            }
        };
        // 处理详情返回数据格式
        const formatData = (data) => {
            const newData = _.cloneDeep(data);
            !newData.propertyRightFile && (newData.propertyRightFile = []);
            !newData.cdcFile && (newData.cdcFile = []);
            return newData;
        };
        const { orgName, userName, userCode } = store.getters.user;
        // eslint-disable-next-line complexity
        const _initData = async (id) => {
            const orgArr = orgName.split('_');
            detailDataLocal.value.applicantDepartName = orgArr[orgArr.length - 2];
            detailDataLocal.value.applicant = userCode;
            detailDataLocal.value.applicantName = userName;
            const { projectId, applicationId } = ctx.$route.query;
            let res = null;
            detailLoading.value = true;
            if (id || applicationId) {
                res = await opportunityApplicationApi({ applicationId: id || applicationId });
                readonly.value = true;
            } else if (projectId) {
                res = await neoCrmProjectQueryApi({ projectId });
                readonly.value = false;
            }
            detailLoading.value = false;
            if (res?.code === '0' && res.data) {
                opportunityTableData.value = res.data.applicationDetailList || [];
                if (res.data.applicationId) {
                    const iflowRes = await contractWorkflowList({ contractCode: res.data.applicationId });
                    if (+iflowRes.code === 0 && iflowRes.data && iflowRes.data.list && iflowRes.data.list.length) {
                        const { mipId, mipType, mipStatus } = iflowRes.data.list[0];
                        const publicPrarms = { fdId: mipId, loginName: userCode };
                        const currentNodeRes = await getProcessInfoApi(publicPrarms);
                        let mipNode = '';
                        let handlePerson = '';
                        if (currentNodeRes && +currentNodeRes.code === 0 && currentNodeRes.data && currentNodeRes.data.currNodes && currentNodeRes.data.currNodes.length) {
                            currentNodeRes.data.currNodes.forEach((item, index) => {
                                mipNode += item.nodeName + `${index === currentNodeRes.data.currNodes.length - 1 ? '' : '、'}`;
                                if (item.handlers && item.handlers.length) {
                                    item.handlers.forEach((sub, index) => {
                                        handlePerson += sub.name + `${index === item.handlers.length - 1 ? '' : '、'}`;
                                    });
                                }
                            });
                        }
                        const object = {
                            ...res.data,
                            mipFlowId: mipId,
                            mipType,
                            mipStatus,
                            mipNode,
                            handlePerson
                        };
                        handleInfosChange({ form: formatData(object) });
                    } else {
                        handleInfosChange({ form: formatData(res.data) });
                    }
                } else {
                    handleInfosChange({ form: formatData(res.data) });
                }
            }
        };
        const isHandleEdit = ref(false);
        const handleInfosChange = (formObjData = {}) => { // 表单组件编辑回调，更新本地数据
            if (formObjData.form && formObjData.form.businessType && !formObjData.form.businessType.includes('快递')) {
                // 业务类别不包含快递时，清空聚焦业态和是否控仓；
                formObjData.form.focusBusinessFormats = '';
                formObjData.form.isWarehouse = '';
            }
            const { itemSetting } = formObjData;
            // 监控表单是否编辑过
            isHandleEdit.value = Boolean(itemSetting);
            if (!itemSetting) { // 页面初始化、刷新时走：
                detailDataLocal.value = Object.assign({}, detailDataLocal.value, formObjData.form);
                if (detailDataLocal.value.mipFlowId) {
                    handleCreate(); // 渲染iflow流程
                }
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.clearValidate(); // 重置校验报错
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.refleshPage(detailDataLocal.value); // 刷新表单子组件
            } else { // collapse组件编辑回调时走：
                const { propertyRightFile, cdcFile, cdcScore1, cdcScore2, cdcScore, riskItemsNum } = detailDataLocal.value;
                // 除了插槽参数以外，其他参数取最新编辑的参数更新(因：插槽的编辑不会触发collapse组件change事件回调，因此change事件回调的插槽的参数是旧数据不能取)
                detailDataLocal.value = Object.assign(
                    {},
                    detailDataLocal.value,
                    formObjData.form,
                    { propertyRightFile, cdcFile, cdcScore1, cdcScore2, cdcScore, riskItemsNum } // 保证插槽数据用最新的
                );
            }
        };
        const TIME_OUT = 500;
        const handleCreate = () => {
            if (deepRefresh.value) { // 流程分支数据改变，需要整个页面刷新（iframe标签v-if刷新、reload刷新均失效的bug）
                deepRefresh.value = false;
                ctx.$router.go(0);
                return false;
            }
            mipUrl.value = '';
            setTimeout(() => {
                mipUrl.value = `${configurl.iflowUrl}?fdId=${detailDataLocal.value.mipFlowId}&fdTemplateKey=CRM_opp_to_admit&userCode=${userCode}&userName=${userName}`;
                createMetaNode();
                window.addEventListener('message', messageFunc, false);
            }, TIME_OUT);
        };
        const createMetaNode = () => {
            const mipmeta = document.getElementById('mipmeta');
            if (mipmeta) {
                // const iframe = document.getElementById('myIframe');
                // iframe.contentWindow.location.reload(true);
                return false;
            }
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = 'upgrade-insecure-requests';
            meta.id = 'mipmeta';
            document.getElementsByTagName('head')[0].appendChild(meta);
        };
        const messageFunc = (event) => {
            if (event.data && event.data.data && event.data.success) {
                const { projectId, applicationId } = detailDataLocal.value;
                const { origin, pathname } = window.location;
                const params = event.data.data || {};
                const { tenantCode } = store.getters.currentTenant;
                params.tenantCode = tenantCode;
                // params.currentApplicationCode = this.applicationCode;
                params.currentApplicationCode = 'APP201904230047';
                if (params.formParam) {
                    // params.formParam.formInstanceId = this.$route.query.id;
                    params.formParam.formInstanceId = applicationId;
                    const { processParam } = params;
                    let isToOtherPersons = '';
                    if (processParam.operationType === 'circulate' && processParam.toOtherPersons) {
                        isToOtherPersons = processParam.toOtherPersons; // 被传阅的人
                    }
                    // eslint-disable-next-line max-len
                    params.formParam.fdUrl = `${origin}${pathname}#/opportunityApplication?projectId=${projectId}&applicationId=${applicationId}${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                }
                btnloading.value = true;
                approveProcess(params).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('提交成功');
                        ctx.$router.go(0);
                    } else {
                        Message.error(res.msg);
                    }
                }).finally(() => {
                    btnloading.value = false;
                });
            }
        };
        const deepRefresh = ref(false);
        const between = (haystack) => {
            const matches = haystack.match(/\((.*?)\)/);
            return matches ? matches[1] : '';
        };
        // eslint-disable-next-line complexity
        const submitMip = async () => {
            const { applicationId, mipFlowId, creditScoreCheck } = detailDataLocal.value;
            const checkMip = between(creditScoreCheck, '(', ')');
            // 当前提交人！== 信用积分人 &&  流程状态 草稿、驳回、撤回
            if (userCode !== checkMip && [1, 5, 6].includes(+detailDataLocal.value.mipStatus)) {
                return Message.warning('信用积分校验人员与开始节点的人员不一致，请编辑-暂存后重新提交校验');
            };
            if (applicationId) {
                const res = await opportunityApplicationApi({ applicationId });
                if (+res.code === 0) {
                    if ((res.data && +res.data.isPass !== 1) || !res.data) {
                        Message.warning('页面数据已经过期，请刷新页面重新提交校验');
                        deepRefresh.value = true;
                        return false;
                    }
                } else {
                    return false;
                }
                const iflowRes = await contractWorkflowList({ contractCode: applicationId });
                if (+iflowRes.code === 0) {
                    if (iflowRes.data && iflowRes.data.list && iflowRes.data.list.length && iflowRes.data.list[0].mipId
                        && iflowRes.data.list[0].mipId === mipFlowId) {
                        // 通过的，不做卡控
                    } else {
                        Message.warning('页面审批流程信息已过期，请刷新页面再试');
                        deepRefresh.value = true;
                        return false;
                    }
                } else {
                    return false;
                }
            }
            const content = window;
            content.frames && content.frames.length && content.frames[0]
                && content.frames[0].postMessage('mbpm_getApprovalData', mipUrl.value);
            content.frames && content.frames.length && content.frames[1]
                && content.frames[1].postMessage('mbpm_getApprovalData', mipUrl.value); // 解决接入了智能客服后，content.frames[0]被占用了的问题
        };
        const handleEdit = () => {
            detailDataLocal.value.isPass = '2';
            readonly.value = false;
            setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.refleshPage(detailDataLocal.value); // 刷新表单子组件
        };
        const saveLoading = ref(false);
        const handleSave = () => {
            // const data = _.cloneDeep(setupContext.refs.upholdHealder.onsave());
            const data = setupContext.refs.upholdHealder.localizeData;
            // if (!data) return;
            /**
             * 因组件问题，插槽触发的额外参数修改（cdcScore1, cdcScore2, cdcScore, riskItemsNum）不能同步至colla子组件本地localizeData数据，
             * 导致返回的data(子组件本地localizeData数据)中的插槽数据不是最新的，所以插槽数据需手动从detailDataLocal.value取最新值
             */
            const { propertyRightFile, cdcFile, cdcScore1, cdcScore2, cdcScore, riskItemsNum } = detailDataLocal.value;
            const params = { ...data, propertyRightFile, cdcFile, cdcScore1, cdcScore2, cdcScore, riskItemsNum };
            if (!params.applicationId || [1, 5, 6].includes(+params.mipStatus)) {
                params.isPass = '2'; // 不通过
            }
            params.isCDC = isCDC.value ? '1' : '0';
            saveLoading.value = true;
            neoStagingProjectApi(params).then(res => {
                if (+res.code === 0) {
                    Message.success('暂存成功');
                    readonly.value = true;
                    isHandleEdit.value = false;
                    const applicationId = res.data ? res.data.applicationId : '';
                    detailDataLocal.value.applicationId = applicationId || detailDataLocal.value.applicationId; // 先把applicationId存起来
                    // 更新地址栏url参数
                    const { origin, pathname } = window.location;
                    window.location.assign(`${origin}${pathname}#/opportunityApplication?projectId=${params.projectId}&applicationId=${applicationId}`);
                    if (deepRefresh.value) { // 流程分支数据改变，需要整个页面刷新（iframe标签v-if刷新、reload刷新均失效的bug）
                        deepRefresh.value = false;
                        ctx.$router.go(0);
                        return false;
                    }
                    refleshPage(applicationId);
                }
            }).finally(() => { saveLoading.value = false; });
        };
        const checkLoading = ref(false);
        const handleCheck = () => {
            const data = _.cloneDeep(setupContext.refs.upholdHealder.onsave());
            if (!data) { return false; };
            // 因组件问题，插槽触发的额外参数修改（cdcScore1, cdcScore2, cdcScore, riskItemsNum）不能同步至父组件，需手动取值
            const { propertyRightFile, cdcFile, cdcScore1, cdcScore2, cdcScore, riskItemsNum } = detailDataLocal.value;
            const params = { ...data, propertyRightFile, cdcFile, cdcScore1, cdcScore2, cdcScore, riskItemsNum };
            checkLoading.value = true;
            neoSyncProjectApi(params).then(res => {
                if (+res.code === 0) {
                    Message.success('校验通过');
                    readonly.value = true;
                    isHandleEdit.value = false;
                    activeCollapses.value = []; // 收起折叠筐
                    if (deepRefresh.value) { // 流程分支数据改变，需要整个页面刷新（iframe标签v-if刷新、reload刷新均失效的bug）
                        deepRefresh.value = false;
                        ctx.$router.go(0);
                        return false;
                    }
                    refleshPage(res.data ? res.data.applicationId : null);
                }
            }).finally(() => { checkLoading.value = false; });
        };
        const upLoadData = async (form, itemSetting, data) => {
            const { prop } = itemSetting;
            if (data && +data.code === 0) {
                isHandleEdit.value = true;
                form[prop].push({ fileName: data.data.name, fileUrl: data.data.downUrl });
                const formRefs = setupContext.refs.upholdHealder.$refs.dcForm;
                if (prop === 'cdcFile') {
                    if (itemSetting.rule[0].required) {
                        const targetRef = formRefs.find(item => item._props.refName === 'CDCForm');
                        itemSetting.rule[0].required && targetRef.$refs['CDCForm'].validateField(prop); // 校验附件
                    }
                    // 调用附件解析接口
                    form.riskItemsNum = 3;
                    // CDC重大风险项为高风险时，重大风险项描述必填，不为高风险时清空重大风险项描述；
                    const res = await cdcFileAnalysisApi({ fileUrl: data.data.downUrl });
                    if (+res.code === 0 && res.data) {
                        const { cdcScore1, cdcScore2, cdcScore, riskItemsNum } = res.data;
                        form.cdcScore1 = cdcScore1;
                        form.cdcScore2 = cdcScore2;
                        form.cdcScore = cdcScore;
                        form.riskItemsNum = riskItemsNum;
                        // 风险项riskItemsNum__c<3, 时清空重大风险项描述
                        setTimeout(() => {
                            if (+riskItemsNum < 3) {
                                form.riskItemsDescribe = '';
                                detailDataLocal.value.riskItemsDescribe = '';
                            }
                        });
                        // form = Object.assign({}, form, res.data);
                        detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
                    }
                } else if (prop === 'propertyRightFile') {
                    if (itemSetting.rule[0].required) {
                        const targetRef = formRefs.find(item => item._props.refName === 'riskForm');
                        targetRef.$refs['riskForm'].validateField(prop);
                    }
                    detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
                }
            }
        };
        const handleDeleteFile = (form, itemSetting, index) => {
            const { prop } = itemSetting;
            form[prop].splice(index, 1);
            detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
            isHandleEdit.value = true;
            const formRefs = setupContext.refs.upholdHealder.$refs.dcForm;
            if (prop === 'cdcFile' && itemSetting.rule[0].required) {
                const targetRef = formRefs.find(item => item._props.refName === 'CDCForm');
                targetRef.$refs['CDCForm'].validateField(prop); // 校验附件
            } else if (prop === 'propertyRightFile' && itemSetting.rule[0].required) {
                const targetRef = formRefs.find(item => item._props.refName === 'riskForm');
                targetRef.$refs['riskForm'].validateField(prop);
            }
        };
        const handleDownloadMould = () => {
            cdcFileTemplateDownloadApi({ fileName: 'CDC健康度评分表.xlsx' });
        };
        const tableKey = ref(false);
        onActivated(() => {
            tableKey.value = !tableKey.value;
        });
        watch( // 注意：加了immediate: true的话，watch监听要在放在最后，否则报错涉及的调用方法找不到！
            () => ctx.$route,
            (newVal) => {
                if (newVal.name === 'opportunityApplication') {
                    const { projectId, applicationId } = detailDataLocal.value;
                    !projectId && !applicationId && (newVal.query.projectId || newVal.query.applicationId) && refleshPage();
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        return {
            config,
            COLLAPSE,
            detailLoading,
            btnloading,
            disabledFlag,
            code,
            detailDataLocal,
            mipUrl,
            refleshPage,
            handleInfosChange,
            submitMip,
            readonly,
            handleEdit,
            handleSave,
            handleCheck,
            upLoadData,
            opportunityTableData,
            handleDeleteFile,
            handleDownloadMould,
            activeCollapses,
            tableHeight,
            tableKey,
            saveLoading,
            checkLoading,
            lodash
        };
    }
};
</script>
<style lang="less">
@scrollbarThumbColor: rgba(0,0,0, .2);
@scrollbarThumbColorHover: rgba(0,0,0, .4);

.mip-iframe {
    overflow: auto;
    .application-detail {
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
            }
            .header-info {
                width: 100%;
                display: flex;
                font-size: 14px;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
                .tips {
                    flex: 1;
                    color: red;
                }
                .apply-info {
                    width: 200px;
                    line-height: 20px;
                    color: #606266;
                }
            }
        }
        .upload-demo {
            display: inline-block;
        }
        .down-mould-btn {
            margin-left: 5px;
        }
        .upload-tips {
            display: inline-block;
            margin-left: 10px;
            line-height: 28px;
            font-size: 12px;
            color: #606266;
        }
        .file-infos {
            width: 100%;
            padding: 0 10px 0 0;
            .file-list {
                width: 100%;
                padding: 2px 0 2px 4px;
                display: flex;
                // justify-content: space-between;
                .file-name {
                    // flex: 1;
                    width: 500px;
                    padding-right: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
                .view-btn, .delete-btn {
                    width: 200px;
                    display: flex;
                    a {
                        text-decoration: none;
                        color: #4285F5;
                    }
                    .el-button {
                        margin-left: 10px;
                    }
                }
            }
        }
        .el-collapse-item__content {
            padding-bottom: 0;
        }
        .el-collapse-item__arrow {
            margin: 0 8px 0 5px;
        }
        .el-button.is-disabled.is-plain,
        .el-button.is-disabled.is-plain:focus,
        .el-button.is-disabled.is-plain:hover {
            border-color: #FFF;
        }
    }
    .ifr {
        width: 100%;
        height: 100vh;
        border: none;
    }
    .detail-toolBar {
        .item-flow-right {
            float: right;
            &.refresh {
                margin-left: 10px;
            }
        }
    }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .file-info {
        .el-collapse-item__header {
            background-color: #edeff6;
            padding-left: 5px;
            font-size: 14px;
            font-weight: 600;
        }
        ::-webkit-scrollbar {
            width:8px;
            height:6px;
        }
        // 滚动块样式
        ::-webkit-scrollbar-thumb {
            background-color:@scrollbarThumbColor;
        }
        ::-webkit-scrollbar-thumb:hover {
            background-color:@scrollbarThumbColorHover;
        }
    }
    .header-operation {
        margin-top: 10px;
    }
}
.no-auth {
    font-size: 16px;
}
</style>
