/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
import { positiveThirteenAndSix } from '@/modules/mdm/utils/regList.js'
// 表单配置信息
export const collapse = [
    {
        title: '是否聚焦',
        ref: 'focusForm',
        formName: 'focusInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '项目名称',
                prop: 'projectName',
                value: 'projectName',
                sourceType: 'input',
                type: 'input',
                rule: [{ required: true, message: '项目名称不能为空', trigger: 'change' }]
            },
            {
                label: '招标结束时间',
                prop: 'closeDate',
                sourceType: 'time',
                type: 'time',
                timeType: 'datetime',
                rule: [{ required: true, message: '招标结束时间不能为空', trigger: 'change' }],
                disabled: false,
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss'
            },
            {
                label: '项目编码',
                prop: 'projectCode',
                value: 'projectCode',
                type: 'custom'
            },
            {
                label: '客户名称',
                prop: 'customerName',
                value: 'customerName',
                type: 'custom',
                rule: [{ required: true, message: '客户名称不能为空', trigger: 'change' }]
            },
            {
                label: '客户行业',
                prop: 'customerIndustry',
                value: 'customerIndustry',
                type: 'custom'
            },
            {
                label: '客户系',
                prop: 'customerGroupName',
                value: 'customerGroupName',
                type: 'custom',
                // optionsKey: 'SYS_CUST_RELATION_TYPE'
            },
            {
                label: '客户等级',
                prop: 'customerLevel',
                value: 'customerLevel',
                type: 'custom',
                optionsKey: 'SYS_WM_CUSTOMER_LEVEL'
            },
            {
                label: '客户营业收入(万)',
                prop: 'customerRevenue',
                value: 'customerRevenue',
                type: 'custom'
            },
            {
                label: '整体投标物流规模(万)',
                prop: 'overallBiddingLogisticsScale',
                value: 'overallBiddingLogisticsScale',
                type: 'custom'
            },
            {
                label: '预计中标规模(万)',
                prop: 'expectedWinningScale',
                value: 'expectedWinningScale',
                type: 'custom'
            },
            {
                label: '安得目前业务规模(万)',
                prop: 'anntoCurrentBusinessScale',
                value: 'anntoCurrentBusinessScale',
                type: 'custom'
            },
            {
                label: '运输或存储的货物',
                prop: 'goodsTransportedOrStored',
                value: 'goodsTransportedOrStored',
                sourceType: 'input',
                type: 'input',
                rule: [{ required: true, message: '运输或存储的货物不能为空', trigger: 'change' }]
            },
            {
                label: '聚焦业态',
                prop: 'focusBusinessFormats',
                value: 'focusBusinessFormats',
                sourceType: 'select',
                type: 'select',
                optionsKey: 'NEOCRM_FOCUS_FORMAT',
                rule: [
                    { required: true, message: '聚焦业态不能为空', trigger: 'change' }
                ]
            },
            {
                label: '聚焦区域',
                prop: 'focusArea',
                value: 'focusArea',
                // sourceType: 'select',
                type: 'custom',
                optionsKey: 'NEOCRM_FOCUS_AREA'
            },
            {
                label: '是否控仓',
                prop: 'isWarehouse',
                sourceType: 'select',
                type: 'select',
                placeholder: '',
                optionsKey: 'NEOCRM_YES_NO',
                rule: [{ required: true, message: '是否控仓不能为空', trigger: 'change' }]
            }
        ]
    },
    {
        title: '运营能力',
        ref: 'capabilityForm',
        formName: 'capabilityInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '业务类别',
                prop: 'businessType',
                type: 'custom',
                // sourceType: 'select',
                // type: 'select',
                // multiple: true,
                // rule: [{ required: false, message: '业务类别不能为空', trigger: 'change' }],
                // placeholder: '',
                // optionsKey: 'SYS_BMS_BUSI_TYPE'
            },
            {
                label: '业务大类',
                prop: 'bigBusinessType',
                // sourceType: 'input',
                type: 'custom',
                // sourceType: 'select',
                // type: 'select',
                // rule: [{ required: true, message: '业务大类不能为空', trigger: 'change' }],
                // placeholder: '',
                // optionsKey: 'SYS_LMDM_BUS_TYPE'
            }
        ]
    },
    {
        title: '财务相关',
        ref: 'financeForm',
        formName: 'financeInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '上游回款周期(票后/天)',
                prop: 'upstreamPaymentCycle',
                type: 'custom',
            },
            {
                label: '项目利润率(%)',
                prop: 'profitMargin',
                sourceType: 'input',
                type: 'input',
                maxlength: 8,
                rule: [{ required: true, message: '项目利润率不能为空', trigger: 'blur' }]
            },
            {
                label: '结算方式',
                prop: 'settlementMode',
                sourceType: 'select',
                type: 'select',
                rule: [
                    { required: true, message: '结算方式不能为空', trigger: 'change' }
                ],
                optionsKey: 'NEOCRM_SETTLEMENT',
                placeholder: ''
            },
            {
                label: '承兑比例(%)',
                prop: 'acceptanceRate',
                sourceType: 'input',
                type: 'input',
                maxlength: 8,
                rule: [
                    { required: true, message: '承兑比例不能为空', trigger: 'blur' }
                ]
            },
            {
                label: '投标押金总和(万)',
                prop: 'totalBidDeposit',
                type: 'custom',
            },
            {
                label: '投标押金比例(%)',
                prop: 'totalBidRatio',
                type: 'custom',
                rule: [
                    { required: true, message: '投标押金比例不能为空', trigger: 'blur' }
                ]
            },
            {
                label: '运作押金总和(万)',
                prop: 'operateDeposit',
                type: 'custom',
            },
            {
                label: '运作押金比例(%)',
                prop: 'operateDepositRatio',
                type: 'custom',
            }
        ]
    },
    {
        title: '合作意向',
        ref: 'cooperationForm',
        formName: 'cooperationInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '机会点与切入点',
                prop: 'opportunityAndEntryPoints',
                value: 'opportunityAndEntryPoints',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 12,
                rule: [{ required: true, message: '机会点与切入点不能为空', trigger: 'blur' }],
                disabled: false
            },
            {
                label: '客情关系',
                prop: 'customerRelationship',
                value: 'customerRelationship',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 12,
                rule: [{ required: true, message: '客情关系点不能为空', trigger: 'blur' }],
                disabled: false
            },
            {
                label: '竞争对手',
                prop: 'competitor',
                value: 'competitor',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 12,
                rule: [{ required: true, message: '竞争对手不能为空', trigger: 'blur' }],
                disabled: false
            },
            {
                label: '竞对客情关系',
                prop: 'competitiveCustomerRelationship',
                value: 'competitiveCustomerRelationship',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 12,
                rule: [{ required: true, message: '竞对客情关系不能为空', trigger: 'blur' }],
                disabled: false
            }
        ]
    },
    {
        title: '风险评估',
        ref: 'riskForm',
        formName: 'riskInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '客户与货物的货权关系',
                prop: 'propertyRight',
                value: 'propertyRight',
                sourceType: 'select',
                type: 'select',
                placeholder: '',
                optionsKey: 'NEOCRM_PROPERTY_RIGHT',
                rule: [{ required: true, message: '客户与货物的货权关系不能为空', trigger: 'change' }]
            },
            {
                label: '是否特批',
                prop: 'isSpecialApproval',
                type: 'custom',
                optionsKey: 'NEOCRM_YES_NO'
            },
            {
                label: '风险等级(综合)',
                prop: 'overallRiskLevel',
                type: 'custom',
            },
            {
                label: '双非特批附件',
                prop: 'propertyRightFile',
                value: 'propertyRightFile',
                type: 'custom',
                span: 24,
                rule: [
                    {
                        required: false,
                        validator: (rule, value, callback) => {
                            if (rule.required && !value.length) {
                                callback(new Error('双非特批附件不能为空'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
            },
            {
                label: '特批原因',
                prop: 'specialReason',
                value: 'specialReason',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 12,
                rows: 4,
                disabled: true
            },
            {
                label: '风险原因(综合)',
                prop: 'overallRiskInfo',
                value: 'overallRiskInfo',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 12,
                rows: 4,
                disabled: true
            },
            {
                label: '税收违法记录(综合)',
                prop: 'overallTaxContravention',
                value: 'overallTaxContravention',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 12,
                rows: 4,
                disabled: true
            },
            {
                label: '不良信用记录',
                prop: 'badCreditRecord',
                value: 'badCreditRecord',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 12,
                rows: 4,
                disabled: true
            },
            {
                label: '违约责任与损失',
                prop: 'defaultLiabilityAndLoss',
                value: 'defaultLiabilityAndLoss',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
        ]
    },
    {
        title: '中风险引入承诺',
        ref: 'promiseForm',
        formName: 'promiseInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '回款管控措施承诺',
                prop: 'collectionControl',
                value: 'collectionControl',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 12,
                rule: [{ required: false, message: '回款管控措施承诺不能为空', trigger: 'blur' }],
                disabled: false
            },
            {
                label: '坏账追责承诺',
                prop: 'badDebtAccountability',
                value: 'badDebtAccountability',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 12,
                rule: [{ required: false, message: '坏账追责承诺不能为空', trigger: 'blur' }],
                disabled: false
            },
            {
                label: '合作额度控制承诺(万)',
                prop: 'cooperationQuota',
                value: 'cooperationQuota',
                sourceType: 'input',
                type: 'input',
                maxlength: 18,
                rule: [{ required: false, message: '合作额度控制承诺不能为空', trigger: 'blur' },
                {
                    pattern: positiveThirteenAndSix,
                    message: '最多13位数字且最多保留2位小数',
                    trigger: 'blur'
                }
                ]
            },
            {
                label: '承诺人',
                prop: 'promisee',
                value: 'promisee',
                // sourceType: 'advance',
                // type: 'advance',
                sourceType: 'input',
                type: 'input',
                rule: [
                    { required: false, message: '承诺人不能为空', trigger: ['change', 'blur'] }
                ],
                // advanceConfig: [
                //     { 'prop': 'esusUserNameCn', 'value': '姓名' },
                //     { 'prop': 'esusLoginName', 'value': '美信号' }
                // ],
                // tableConfig: [
                //     { 'prop': 'esusId', 'value': 'ID' },
                //     { 'prop': 'esusLoginName', 'value': '美信号' },
                //     { 'prop': 'esusUserNameCn', 'value': '姓名' },
                //     { 'prop': 'esusPositionName', 'value': '职位' },
                //     { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                // ],
                // prefix: '/api-mdm',
                // advanceUrl: `/selectUserCompany`,
                // cbParams: ['esusUserNameCn#promisee', 'esusId#promiseeCode']
            },
        ]
    },
    {
        title: 'CDC仓准入',
        ref: 'CDCForm',
        formName: 'CDCInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: 'CDC准入评估附件',
                prop: 'cdcFile',
                value: 'cdcFile',
                type: 'custom',
                rule: [{
                    required: false,
                    validator: (rule, value, callback) => {
                        if (rule.required && !value.length) {
                            callback(new Error('CDC准入评估附件不能为空'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }],
                span: 24
            },
            {
                label: '基本信息调研得分',
                prop: 'cdcScore1',
                value: 'cdcScore1',
                type: 'custom',
                rule: [{ required: false, message: '基本信息调研得分不能为空', trigger: 'change' }]
            },
            {
                label: '复杂度评估得分',
                prop: 'cdcScore2',
                value: 'cdcScore2',
                type: 'custom',
                rule: [{ required: false, message: '复杂度评估得分不能为空', trigger: 'change' }]
            },
            {
                label: '综合得分',
                prop: 'cdcScore',
                value: 'cdcScore',
                type: 'custom',
                rule: [{ required: false, message: '综合得分不能为空', trigger: 'change' }]
            },
            {
                label: '重大风险项',
                prop: 'riskItemsNum',
                value: 'riskItemsNum',
                type: 'custom',
                rule: [{ required: false, message: '重大风险项不能为空', trigger: 'change' }]
            },
            {
                label: '重大风险项描述',
                prop: 'riskItemsDescribe',
                value: 'riskItemsDescribe',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                placeholder: '',
                maxlength: 1024,
                rows: 4,
                showWordLimit: true,
                span: 24,
                rule: [{ required: false, message: '重大风险项描述不能为空', trigger: 'blur' }],
                disabled: false
            },
        ]
    },
    {
        title: '商机明细',
        ref: 'opportunityForm',
        formName: 'opportunityInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'sjmx',
                value: 'sjmx',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '商机名称',
                        minWidth: 160,
                        prop: 'opportunityName',
                        sortable: true
                    },
                    {
                        label: '分公司',
                        prop: 'companyName',
                        minWidth: 160,
                        sortable: true
                    },
                    {
                        label: '商机大类',
                        prop: 'opportunityCategory',
                        type: 'select',
                        optionsKey: 'NEOCRM_BUSINESS_TYPE',
                        minWidth: 160
                    },
                    {
                        label: '业务类别',
                        prop: 'businessType',
                        // type: 'select',
                        // optionsKey: 'SYS_BMS_BUSI_TYPE',
                        minWidth: 160
                    },
                    {
                        label: '仓库类型',
                        prop: 'whType',
                        type: 'select',
                        optionsKey: 'NEOCRM_WH_TYPE',
                        minWidth: 160
                    },
                    {
                        label: '安得预计总规模（万）',
                        prop: 'anntoExpectedTotalScale',
                        minWidth: 160
                    },
                    {
                        label: '签单计划（万）',
                        prop: 'signingPlan',
                        minWidth: 160
                    },
                    {
                        label: '销售产品',
                        prop: 'promotingProduct',
                        minWidth: 160
                    },
                    {
                        label: '账期（票后/天）',
                        prop: 'accountPeriod',
                        minWidth: 160
                    },
                    {
                        label: '投标押金比例(%)',
                        prop: 'bidDepositRatio',
                        minWidth: 160
                    },
                    {
                        label: '战略合作方',
                        prop: 'teamAccount',
                        minWidth: 160
                    },
                    {
                        label: '客户分类',
                        prop: 'customerClassify',
                        type: 'select',
                        optionsKey: 'PMS_CUSTOMER_CLASSIFY',
                        minWidth: 160
                    },
                    {
                        label: '风险等级',
                        prop: 'riskLevel',
                        minWidth: 100
                    },
                    {
                        label: '备注',
                        prop: 'remark',
                        minWidth: 160
                    }
                ]
            },
        ]
    },
    {
        title: '系统信息',
        ref: 'systemForm',
        formName: 'systemInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '商机准入申请编号',
                prop: 'applicationNo',
                value: 'applicationNo',
                type: 'custom'
            },
            {
                label: '审批状态（MIP）',
                prop: 'mipStatus',
                value: 'mipStatus',
                type: 'custom',
                optionsKey: 'SYS_SU_MIP_AUDIT_STATUS'
            },
            {
                label: '申请人',
                prop: 'applicantName',
                value: 'applicant',
                type: 'custom'
            },
            {
                label: '申请日期',
                prop: 'applicationDate',
                value: 'applicationDate',
                type: 'custom'
            },
            {
                label: '流程类型（MIP）',
                prop: 'mipType',
                value: 'mipType',
                optionsKey: 'MIP_TYPE',
                type: 'custom'
            },
            {
                label: '当前流程节点',
                prop: 'mipNode',
                value: 'mipNode',
                type: 'custom'
            },
            {
                label: '当前处理人',
                prop: 'handlePerson',
                value: 'handlePerson',
                type: 'custom'
            },
            {
                label: '审批通过日期',
                prop: 'approvedDate',
                value: 'approvedDate',
                type: 'custom'
            },
            // {
            //     label: '接口状态',
            //     prop: 'interfaceStatus',
            //     value: 'interfaceStatus',
            //     type: 'custom'
            // },
            // {
            //     label: '接口信息',
            //     prop: 'interfaceInfo',
            //     value: 'interfaceInfo',
            //     type: 'custom'
            // },
            {
                label: 'MIP流程ID',
                prop: 'mipFlowId',
                value: 'mipFlowId',
                type: 'custom'
            },
            {
                label: 'MIP模板ID',
                prop: 'mipTemplateId',
                value: 'mipTemplateId',
                type: 'custom'
            },
            // {
            //     label: 'MIP系统',
            //     prop: 'mipSystem',
            //     value: 'mipSystem',
            //     type: 'custom'
            // },
            {
                label: '信用积分校验',
                prop: 'creditScoreCheck',
                value: 'creditScoreCheck',
                type: 'custom'
            },
            {
                label: '后台管理员特批',
                prop: 'adminApproved',
                value: 'adminApproved',
                type: 'custom',
                optionsKey: 'NEOCRM_YES_NO'
            },
            {
                label: '是否检验通过',
                prop: 'isPass',
                value: 'isPass',
                type: 'custom',
                optionsKey: 'NEOCRM_YES_NO'
            }
        ]
    }
];
