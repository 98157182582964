import request from '@/utils/http';
import utils from '@/utils/common';
const apiCrmHost = 'api-lcrm';

// 查询商机准入信息
export const opportunityApplicationApi = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/opportunityApplication/query',
        method: 'post',
        data
    });
};
// 查询项目信息
export const neoCrmProjectQueryApi = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/project/query',
        method: 'post',
        data
    });
};
// CDC附件解析接口
export const cdcFileAnalysisApi = (data) => {
    return request({
        url: apiCrmHost + '/cdcFile/analysis',
        method: 'post',
        data
    });
};
// 模板下载
export const cdcFileTemplateDownloadApi = (params) => {
    return request({
        url: apiCrmHost + '/cdcFile/download',
        method: 'get',
        responseType: 'blob',
        params
    }).then(res => {
        utils.downloadStream(res, params.fileName);
    });
};
// 准入暂存接口
export const neoStagingProjectApi = (data) => {
    return request({
        url: apiCrmHost + '/neo/stagingProject',
        method: 'post',
        data
    });
};

// 提交校验接口
export const neoSyncProjectApi = (data) => {
    return request({
        url: apiCrmHost + '/neo/syncProject',
        method: 'post',
        data
    });
};
